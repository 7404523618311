<template>
  <div class="sliding-paragraph">
    <div
      class="sliding-paragraph__description"
      @mouseover="() => (show = true)"
      @mouseleave="() => (show = false)"
    >
      <span class="sliding-paragraph__number">
        {{ sectionKey + 1 }}
      </span>
      <div class="sliding-paragraph__content">
        <div class="content-wrapper">
          <div class="cta-wrapper" v-if="collection.name">
            <CustomCta
              type="label"
              :link="localePath(collection.name['link-url'])"
              :target="collection.name.target || '_blank'"
            >
              <span class="description-label">
                {{ collection.name['link-label'] }}
              </span>
            </CustomCta>
          </div>
          <div class="content-label" v-if="images.length > 0">
            <FysImagesCollection class="desktop-only" :images="images" />
          </div>
        </div>
      </div>
    </div>
    <div :class="'sliding-paragraph__empty position' + (sectionKey + 1)"></div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import FysImagesCollection from '~/components/Home/FysImagesCollection.vue';
import CustomCta from '../General/CustomCta.vue';

export default defineComponent({
  name: 'StyleSectionSlider',
  components: { FysImagesCollection, CustomCta },
  props: {
    collection: {
      type: Object,
      default: () => {},
    },
    sectionKey: {
      type: Number,
      default: 1,
    },
  },

  setup(props) {
    const show = ref(false);
    const { isDesktop } = useWindow();
    const images = computed(() =>
      props.collection?.images?.length > 0
        ? props.collection.images
            .filter((img) => img?.active && img.image?.desktop)
            .map((image) => image.image)
        : []
    );

    return {
      show,
      isDesktop,
      images,
    };
  },
});
</script>

<style lang="scss" scoped>
.sliding-paragraph {
  height: 5rem;
  width: 100%;
  z-index: 1;
  display: flex;
  &__description {
    z-index: 1;
    justify-content: space-between;
    height: 100%;
    display: flex;
    flex-grow: 1;
  }
  &__empty {
    z-index: 1;
    background-color: white;
    height: 5rem;
    border-left: var(--border-width) var(--border-style) var(--border-color);
    &.position {
      &1 {
        width: 4%;
      }
      &2 {
        width: 44%;
      }
      &3 {
        width: 20.533%;
      }
      &4 {
        width: 30.4%;
      }
      &5 {
        width: 4.533%;
      }
      &6 {
        width: 38.933%;
      }
      &7 {
        width: 18.667%;
      }
    }
  }
  &__number {
    padding-left: 1.25rem;
    width: 25px;
    align-items: center;
    display: flex;
    @include paragraph-s;
  }
  &__content {
    display: flex;
    .description-label {
      @include mobile-h6;
    }
    .content-wrapper {
      display: flex;
      .cta-wrapper {
        display: flex;
        padding-right: 0.9375rem;
      }
    }
    .content-label {
      align-self: center;
    }
  }
}
@include from-desktop-min {
  .sliding-paragraph {
    height: 5.625rem;
    &__empty {
      height: 5.625rem;
    }
    &__description {
      .sliding-paragraph__content {
        .content-wrapper {
          transform: translateX(15.4375rem);
          transition: all 0.4s ease-in-out;
        }
      }
      &:hover {
        .sliding-paragraph__content {
          .content-wrapper {
            transform: translateX(0);
          }
        }
      }
    }
    &__empty {
      &.position {
        &1 {
          width: 33.533%;
        }
        &2 {
          width: 63.174%;
        }
        &3 {
          width: 40.719%;
        }
        &4 {
          width: 55.614%;
        }
        &5 {
          width: 32.186%;
        }
        &6 {
          width: 61.303%;
        }
        &7 {
          width: 39.297%;
        }
      }
    }
    &__number {
      @include label-s;
    }
    &__content {
      .description-label {
        @include desktop-h6;
      }
      .content-wrapper {
        display: flex;
        .cta-wrapper {
          display: flex;
          padding-right: 1.5625rem;
        }
      }
    }
  }
}
</style>
