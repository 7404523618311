<template>
  <div class="find-your-style" v-if="collections.length > 0">
    <p class="style__heading">{{ data.title }}</p>
    <div class="style__sections">
      <div
        class="style__section"
        v-for="(collection, index) in collections"
        :key="index"
      >
        <StyleSectionSlider :section-key="index" :collection="collection" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import StyleSectionSlider from '~/components/Home/StyleSectionSlider.vue';

export default defineComponent({
  name: 'FindYourStyle',
  components: {
    StyleSectionSlider,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const collections = computed(() =>
      props.data?.collections?.length > 0
        ? props.data.collections.filter((c) => c?.active)
        : []
    );

    return {
      collections,
    };
  },
});
</script>

<style lang="scss" scoped>
.style__heading {
  @include mobile-h5;
  padding-left: 1.25rem;
  padding-bottom: 2.5rem;
}
.style__sections {
  .style__section {
    border-top: var(--border-width) var(--border-style) var(--border-color);
    &:last-child {
      border-top: var(--border-width) var(--border-style) var(--border-color);
      border-bottom: var(--border-width) var(--border-style) var(--border-color);
    }
  }
}
@include from-desktop-min {
  .style__heading {
    @include desktop-h4;
    padding-left: 1.25rem;
    padding-bottom: 2.5rem;
  }
}
</style>
