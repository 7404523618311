<template>
  <div class="images-collection">
    <nuxt-img
      v-for="(image, index) in images"
      :key="`fys-image-${index}`"
      class="fys-image"
      :src="image.desktop"
      :alt="image.alt"
      width="74"
      height="74"
      placeholder="/logo/logo-icon-black.svg"
      format="webp"
      fit="contain"
      :preload="false"
      loading="lazy"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FysImagesCollection',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
});
</script>

<style lang="scss" scoped>
@include from-desktop-min {
  .images-collection {
    height: 5rem;
    display: flex;
    justify-content: center;
    .fys-image {
      align-self: center;
      padding: 0.5rem 0.5rem 0.5rem 0;
    }
  }
}
</style>
